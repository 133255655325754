<script setup lang="ts">
import { nextTick, onMounted, onUnmounted, ref } from 'vue'
import WaveSurfer from 'wavesurfer.js'
import { api } from '@src/api'
import { useAuthStore } from '@src/store/auth.ts'
import Diary from '@src/components/Diary.vue'
import loadSecImage from '@src/loadSecImage.ts'
import { DiaryType } from '@src/api/diaries.ts'

const authStore = useAuthStore()
const diariesList = ref<Record<number, typeof Diary>>({})
const diaries = ref<DiaryType[]>([])
const diariesNonDraftCount = ref(0)
const parentAvatar = ref('')
const isLoadingDiaries = ref(false)
const currentAudioPlayer = ref<WaveSurfer | null>(null)

const currentPage = ref(1)
const totalPages = ref(1)

const setItemRef = (el: any, id: number) => {
  diariesList.value[id] = (el as typeof Diary)
}

const loadDiaries = (page = 1) => {
  isLoadingDiaries.value = true
  currentPage.value = page

  if (page === 1) {
    diaries.value = []
    diariesList.value = []
  }

  api.diaries
    .list('', page)
    .then((data) => {
      totalPages.value = Math.ceil(data.total / 10)
      diariesNonDraftCount.value = data.total

      data.diaries.forEach((item) => {
        item.type = item.isDraft ? 'draft' : ''
        diaries.value.push(item)
      })

      if (page === 1) {
        nextTick(() => {
          window.scrollTo(0, 0)
        })
      }
    })
    .finally(() => {
      isLoadingDiaries.value = false
    })
}

const handleAudioStart = (player: WaveSurfer) => {
  if (currentAudioPlayer.value !== null) {
    currentAudioPlayer.value.pause()
  }

  currentAudioPlayer.value = player
}

const handleAudioFinish = (id: number) => {
  currentAudioPlayer.value = null
  let isFind = false

  for (let diary of diaries.value) {
    if (!isFind && diary.id === id) {
      isFind = true
      continue
    }

    if (isFind && diary.files && diary.files.length > 0 && diary.files[0].fileType === 'audio') {
      const currentDiary = document.querySelector<HTMLElement>(`[data-diary="${diary.id}"]`)

      if (currentDiary) {
        window.scrollTo({
          top: currentDiary.offsetTop,
          behavior: 'smooth',
        })
        diariesList.value[diary.id].play()
      }

      break
    }
  }
}

const handleWindowScroll = () => {
  if (
    !isLoadingDiaries.value &&
    currentPage.value < totalPages.value &&
    document.body.scrollHeight - 200 < document.documentElement.scrollTop + window.innerHeight
  ) {
    loadDiaries(currentPage.value + 1)
  }
}

onMounted(() => {
  loadDiaries()
  parentAvatar.value = ''

  if (authStore.parent && authStore.parent.avatar !== null) {
    parentAvatar.value = loadSecImage(authStore.parent.avatar, 'thumbnail')
  }

  window.addEventListener('scroll', handleWindowScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleWindowScroll)
})
</script>

<template>
  <main>
    <div class="header">
      <div class="header--count">
        <div class="emotions-ledger"></div>
        <div class="header--count--text">
          <span class="public">
            {{ diariesNonDraftCount }}
            {{
              $pluralize(diariesNonDraftCount, ['запись', 'записи', 'записей'])
            }}
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="isLoadingDiaries || diaries.length > 0"
      class="diaries--wrapper"
    >
      <div class="diaries">
        <Diary
          v-for="(diary, key) in diaries"
          :key="key"
          :ref="(el) => setItemRef(el, diary.id)"
          :item="diary"
          :author-avatar-data="parentAvatar"
          is-enabled
          can-reaction
          @audio-start="handleAudioStart"
          @audio-finish="handleAudioFinish"
        />

        <img
          v-if="isLoadingDiaries"
          class="diaries-loading"
          src="../../assets/img/loading.svg"
          alt="Loading"
        />
      </div>
    </div>
  </main>
</template>

<style scoped lang="scss">
.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &--count {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 12px;

    &--text {
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      opacity: 0.8;

      @media screen and (min-width: $desktop) {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.1;
      }
    }
  }
}

.emotions-ledger {
  --size: 26;

  @media screen and (min-width: $desktop) {
    --size: 32;
  }
}

.diaries {
  margin-top: 10px;
  padding: 10px 0 62px;
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    margin-top: 20px;
  }
}

.diaries-loading {
  margin: 20px auto 0;
  display: block;
  width: 50px;

  @media screen and (min-width: $desktop) {
    margin-top: 60px;
    width: 80px;
  }
}
</style>
