<script setup lang="ts">
import { inject, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { api } from '@src/api'
import { useAuthStore } from '@src/store/auth.ts'
import { LocalEventBus } from '@src/event-bus.ts'
import pkg from '../../package.json'

const router = useRouter()
const authStore = useAuthStore()
const eventBus = inject(LocalEventBus)

const isShowDiary = ref(false)
const isShowPhotoMain = ref(false)
const isMotivationEnabled = ref(false)
const showMenu = ref(false)
const menuItems = ref<
  {
    name?: string
    title: string
    titleAdd?: string
    titleAddColor?: string
    subtitle?: string
    icon: string
    handle?: () => void
    color?: string
    checkbox?: boolean
    changeCheckbox?: (value: boolean) => void
  }[]
>([])

const changeShowDiary = (value: boolean) => {
  api.users.updateShowDiary(value).then((data) => {
    authStore.setShowDiary(data)
  })
}

const changeShowPhotoMain = (value: boolean) => {
  api.users.updateShowPhoto(value).then((data) => {
    authStore.setShowPhotoMain(data)
  })
}

const changeMotivationEnabled = (value: boolean) => {
  api.users.updateMotivationEnabled(value).then((data) => {
    authStore.setMotivationEnabled(data)
  })
}

const dummyAction = () => {}

authStore.$subscribe(
  (_, state) => {
    if (state.user) {
      isShowDiary.value = state.user.isShowDiary
      isShowPhotoMain.value = state.user.isShowPhotoMain
      isMotivationEnabled.value = state.user.isMotivationEnabled
    }

    if (state.accessToken !== null) {
      if (authStore.user?.role === 'child') {
        menuItems.value = [
          {
            name: 'contact',
            title: 'Связаться с нами',
            icon: 'icon-email',
          },
        ]
      } else {
        menuItems.value = [
          {
            title: 'Видимость профиля',
            subtitle: 'показывать ваш профиль в поиске',
            icon: 'icon-eye-on',
            handle: dummyAction,
            checkbox: isShowDiary.value,
            changeCheckbox: changeShowDiary,
          },
          {
            title: 'Показывать фото на главной',
            icon: 'icon-avatar',
            handle: dummyAction,
            checkbox: isShowPhotoMain.value,
            changeCheckbox: changeShowPhotoMain,
          },
          {
            name: 'child',
            title: 'Создать профиль для ребенка',
            icon: 'icon-user-add',
          },
          {
            name: 'tariff',
            title: 'Планы и тарифы',
            titleAdd: 'АКЦИЯ',
            titleAddColor: '#e7125f',
            icon: 'icon-ruble',
          },
          {
            name: 'contact',
            title: 'Связаться с нами',
            icon: 'icon-email',
          },
          {
            title: 'Мотивационная рассылка',
            subtitle: 'Эл.письма для поддержи мужчин',
            icon: 'icon-umbrella',
            handle: dummyAction,
            checkbox: isMotivationEnabled.value,
            changeCheckbox: changeMotivationEnabled,
          },
          {
            name: 'remove',
            title: 'Удалить аккаунт',
            icon: 'icon-trash',
            color: '#ff5858',
          },
        ]
      }
    } else {
      menuItems.value = [
        {
          name: 'register',
          title: 'Создать дневник',
          icon: 'icon-book',
        },
        {
          name: 'search',
          title: 'Найти дневник',
          icon: 'icon-search',
        },
        {
          name: 'tariff',
          title: 'Планы и тарифы',
          titleAdd: 'АКЦИЯ',
          titleAddColor: '#e7125f',
          icon: 'icon-ruble',
        },
        {
          name: 'contact',
          title: 'Связаться с нами',
          icon: 'icon-email',
        },
      ]
    }
  },
  {
    immediate: true,
  },
)

onMounted(() => {
  eventBus?.on('mainMenu', (action) => {
    if (action === 'show') {
      showMenu.value = true
      document.body.classList.add('disable-scroll')
    }

    if (action === 'hide') {
      showMenu.value = false
      document.body.classList.remove('disable-scroll')
    }
  })
})

const handleLogout = () => {
  authStore.clearAccessToken()
  eventBus?.emit('mainMenu', 'hide')
  router.push({ name: 'index' })
}

const closeMenu = () => {
  eventBus?.emit('mainMenu', 'hide')
}
</script>

<template>
  <div
    v-show="showMenu"
    class="main-menu-wrapper"
  >
    <div class="main-menu">
      <div class="main-menu--items">
        <template v-for="(item, index) in menuItems">
          <RouterLink
            v-if="item.name"
            :to="{ name: item.name }"
            class="main-menu--item"
            :style="item.color ? `color:${item.color};` : ''"
            @click="
              () => {
                if (item.handle) {
                  item.handle()
                } else {
                  closeMenu()
                }
              }
            "
          >
            <div
              :class="'main-menu--icon ' + item.icon"
              :style="item.color ? `color:${item.color};` : ''"
            ></div>
            <div class="main-menu--title">
              {{ item.title }}

              <span
                v-if="item.titleAdd"
                :style="`color:${item.titleAddColor}`"
              >
                &nbsp;{{ item.titleAdd }}
              </span>

              <div
                v-if="item.subtitle"
                class="main-menu--subtitle"
              >
                {{ item.subtitle }}
              </div>
            </div>
          </RouterLink>
          <div
            v-else
            class="main-menu--item"
            :style="item.color ? `color:${item.color};` : ''"
            @click="
              () => {
                if (item.handle) {
                  item.handle()
                } else {
                  closeMenu()
                }
              }
            "
          >
            <div
              :class="'main-menu--icon ' + item.icon"
              :style="item.color ? `color:${item.color};` : ''"
            ></div>
            <div class="main-menu--title">
              {{ item.title }}
              <div
                v-if="item.subtitle"
                class="main-menu--subtitle"
              >
                {{ item.subtitle }}
              </div>
            </div>

            <template v-if="item.checkbox !== undefined">
              <input
                type="checkbox"
                :id="'check' + index"
                class="checkbox"
                :value="item.checkbox"
                :checked="item.checkbox"
                @change="
                  (event) => {
                    if (item.changeCheckbox !== undefined) {
                      item.changeCheckbox(
                        (event.target as HTMLInputElement).checked,
                      )
                    }
                  }
                "
              />
              <label :for="'check' + index"></label>
            </template>
          </div>
        </template>
      </div>

      <div class="main-menu--buttons">
        <div
          v-if="authStore.isAuth"
          class="btn primary"
          @click="handleLogout"
        >
          Выйти
        </div>
        <RouterLink
          v-else
          class="btn primary"
          :to="{ name: 'login' }"
          @click="closeMenu"
        >
          Вход
        </RouterLink>
      </div>

      <div class="version">v{{ pkg.version }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.main-menu-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("/images/bg-mobile.jpg") top center no-repeat;
  background-size: cover;
  z-index: 98;

  @media screen and (min-width: $desktop) {
    top: 110px;
    background: rgba(255, 255, 255, 0.6);
  }
}

.main-menu {
  position: absolute;
  top: 105px;
  bottom: 0;
  padding: 0 20px 30px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    top: 10px;
    right: 0;
    padding: 60px;
    justify-content: flex-start;
    width: 620px;
    background: #ffffff;
    border-top-left-radius: 30px;
    box-shadow: 0 20px 40px 0 #cad9ef66;
  }

  &--items {
    width: 100%;
  }

  &--item {
    margin-bottom: 20px;
    padding: 0 10px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 50px;
    color: #222222;
    background: #ffffff;
    border: 1px solid #e7f4f9;
    border-radius: 8px;
    text-decoration: none;
    box-shadow: 0 20px 40px 0 #cad9ef66;
    cursor: pointer;
  }

  &--icon {
    margin-right: 10px;
    display: block;
    width: 24px;
    height: 24px;
    font-size: 24px;
  }

  &--title {
    flex-grow: 1;
  }

  &--subtitle {
    margin-top: 3px;
    color: #0da7f3;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
  }

  &--buttons {
    width: 255px;
    max-width: 100%;

    @media screen and (min-width: $desktop) {
      margin-top: 60px;
    }
  }
}

.version {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 0.6em;
  text-align: center;
  opacity: 0.5;
}
</style>
