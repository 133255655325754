<script setup lang="ts">
import { ref } from 'vue'
import { useField } from 'vee-validate'
import { Schema } from 'yup'

const model = defineModel<string | null>()
const props = defineProps<{
  id: string
  type: 'text' | 'email' | 'date' | 'password' | 'code' | 'search'
  rules?: Schema
  disabled?: boolean
  placeholder?: string
  class?: string
  label?: string
  hint?: string
}>()
const emit = defineEmits<{
  blur: []
  focus: []
  change: []
}>()

const input = ref<HTMLInputElement | null>(null)
const inputType = (() => {
  switch (props.type) {
    case 'code':
    case 'search':
      return 'text'
    default:
      return props.type
  }
})()

const {
  value: validateValue,
  errorMessage,
  handleReset,
  validate: fieldValidate,
  setErrors,
} = useField(props.id, props.rules)

const viewPassword = ref(false)

const onFocus = () => {
  handleReset()
  emit('focus')
}

const onBlur = () => {
  emit('blur')
}

const onChange = () => {
  emit('change')
}

const doFocus = () => {
  input.value?.focus()
}

const validate = async (): Promise<boolean> => {
  validateValue.value = model.value

  return (await fieldValidate()).valid
}

defineExpose({
  doFocus,
  validate,
  setErrors,
})
</script>

<template>
  <div
    :class="`field-wrapper ${label ? 'with-label' : ''} ${props.class ?? ''}`"
  >
    <label
      v-if="label"
      :for="id"
    >
      {{ label }}
    </label>

    <input
      ref="input"
      v-model="model"
      :id="id"
      :type="
        inputType === 'password'
          ? viewPassword
            ? 'text'
            : 'password'
          : inputType
      "
      :class="{
        'with-icon': ['search'].includes(type),
        code: type === 'code',
        error: !!errorMessage,
      }"
      :placeholder="props.placeholder ?? ''"
      :disabled="disabled ?? false"
      @focus="onFocus"
      @blur="onBlur"
      @change="onChange"
    />
    <div
      v-if="type === 'password'"
      class="right-icon"
      :class="{ 'icon-eye-on': viewPassword, 'icon-eye-off': !viewPassword }"
      @click="viewPassword = !viewPassword"
    ></div>
    <div
      v-if="type === 'search'"
      class="right-icon icon-search"
    />

    <div
      v-if="hint"
      v-show="!errorMessage"
      class="hint"
    >
      <span class="icon-mail"></span>
      {{ hint }}
    </div>

    <div
      v-show="!!errorMessage"
      class="error-msg"
    >
      <span class="icon-error"></span>
      {{ errorMessage }}
    </div>

    <slot/>
  </div>
</template>
