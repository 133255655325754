import { request } from '@src/axios.ts'

export default class MetatagResource {
  get(uri: string) {
    return request<{
      title: string
      description: string
    }>({
      method: 'get',
      url: 'metatag',
      params: {
        uri,
      },
    })
  }
}
