import { defineStore } from 'pinia'

export const useWindowStore = defineStore('window', {
  state: () => {
    return {
      width: 0,
      isMobile: false,
      isDesktop: false,
    }
  },

  actions: {
    changeWidth(width: number) {
      this.width = width
      this.isMobile = width < 1200
      this.isDesktop = !this.isMobile
    },
  },
})
