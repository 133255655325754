<script setup lang="ts">
const model = defineModel()
const emit = defineEmits<{
  close: [boolean]
}>()

const btnDisagree = () => {
  emit('close', false)
  model.value = false
}

const btnAgree = () => {
  emit('close', true)
  model.value = false
}
</script>

<template>
  <div
    v-if="model"
    class="terms-wrapper"
  >
    <div class="terms">
      <div class="terms--content">
        <div class="terms--scroll">
          <div class="terms--title h2">Условия</div>

          <div class="terms--subtitle">отметьте ознакомление и согласие</div>

          <div class="terms--blocks">
            <div class="terms--block">
              <h4>Terms of Service</h4>

              <p>
                Computers have become ubiquitous in almost every facet of our
                lives. At work, desk jockeys spend hours in front of their
                desktops, while delivery people scan bar codes with handhelds
                and workers in the field stay in touch with the central office
                via their notebooks. At home, we rely on our desktops and
                notebooks to do our shopping, to entertain us, and to keep us
                abreast of world events. We may not see our email servers, but
                we count on them to deliver our email whenever and wherever we
                want it. Our PDAs keep track of our hectic schedules, our to-do
                lists, our contact lists, Even though computer hardware is
                central to almoste want it. Our PDAs keep track of our hectic
                schedules, our to-do lists, our contact lists, Even though Our
                PDAs keep track of our hectic schedules, our to-do lists, our
                contact lists, Even though computer hardware is central to
                almoste want it. Our PDAs keep track of our hectic schedules,
                our to-do lists, our contact lists, Even though
              </p>
            </div>

            <div class="terms--block">
              <h4>Privacy Policy</h4>

              <p>
                Computers have become ubiquitous in almost every facet of our
                lives. At work, desk jockeys spend hours in front of their
                desktops, while delivery people scan bar codes with handhelds
                and workers in the field stay in touch with the central office
                via their notebooks. At home, we rely on our desktops and
                notebooks to do our shopping, to entertain us, and to keep us
                abreast of world events. We may not see our email servers, but
                we count on them to deliver our email whenever and wherever we
                want it. Our PDAs keep track of our hectic schedules, our to-do
                lists, our contact lists, Even though computer hardware is
                central to almoste want it. Our PDAs keep track of our hectic
                schedules, our to-do lists, our contact lists, Even though Our
                PDAs keep track of our hectic schedules, our to-do lists, our
                contact lists, Even though computer hardware is central to
                almoste want it. Our PDAs keep track of our hectic schedules,
                our to-do lists, our contact lists, Even though
              </p>
            </div>
          </div>
        </div>

        <div class="terms--overlay"></div>
      </div>

      <div class="terms--buttons">
        <div
          class="btn"
          @click="btnDisagree"
        >
          Нет
        </div>

        <div
          class="btn primary"
          @click="btnAgree"
        >
          Согласен
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.terms-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.95);
  z-index: 102;
}

.terms {
  position: absolute;
  top: 50px;
  bottom: 35px;
  left: 15px;
  right: 15px;
  display: flex;
  flex-flow: column nowrap;

  @media screen and (min-width: $desktop) {
    top: 125px;
    bottom: 125px;
    left: 120px;
    right: 120px;
    margin: 0 auto;
    padding: 50px 60px 60px;
    max-width: 1200px;
    max-height: 770px;
    background: #ffffff;
    border-radius: 30px;
    box-shadow: 0 20px 40px 0 #cad9ef66;
    box-sizing: border-box;
  }

  &--content {
    position: relative;
    padding: 20px 0;
    min-height: 0;
    background: #ffffff;
    border-radius: 30px;
    box-shadow: 0 20px 40px 0 #cad9ef66;
    overflow: hidden;

    @media screen and (min-width: $desktop) {
      padding: 0;
      border-radius: 0;
      box-shadow: none;
    }
  }

  &--scroll {
    padding: 0 20px 60px;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    overflow-y: scroll;

    @media screen and (min-width: $desktop) {
      padding: 0 15px 80px;
    }
  }

  &--title {
    margin-bottom: 16px;
    text-align: center;

    @media screen and (min-width: $desktop) {
      font-size: 48px;
      font-weight: 600;
    }
  }

  &--subtitle {
    font-size: 12px;
    font-weight: 400;
    text-align: center;

    @media screen and (min-width: $desktop) {
      font-size: 15px;
      font-weight: 500;
    }
  }

  &--blocks {
    @media screen and (min-width: $desktop) {
      margin-top: 50px;
      display: flex;
      flex-flow: row nowrap;
      gap: 50px;
    }
  }

  &--block {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 400;
    text-align: justify;

    @media screen and (min-width: $desktop) {
      margin-top: 0;
      font-size: 17px;
      font-weight: 500;
      line-height: 120%;
    }

    h4 {
      margin-bottom: 16px;
      color: #015bb0;

      @media screen and (min-width: $desktop) {
        font-size: 32px;
        font-weight: 600;
      }
    }
  }

  &--overlay {
    position: absolute;
    bottom: 0;
    left: 10px;
    right: 10px;
    height: 100px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 1) 80%
    );

    @media screen and (min-width: $desktop) {
      left: 10px;
      right: 18px;
    }
  }

  &--buttons {
    flex: 0 0 auto;
    margin: 20px auto 0;
    display: flex;
    flex-flow: row nowrap;
    gap: 20px;
    width: 335px;
    max-width: 100%;
    height: 52px;

    @media screen and (min-width: $desktop) {
      margin-top: 60px;
    }

    .btn {
      box-shadow: 0 20px 40px 0 #cad9ef66;
    }
  }
}
</style>
