import { request } from '@src/axios.ts'

type AuthLogin = {
  loginOrEmail: string
  password: string
}

type AuthRegistrationFirstStep = {
  firstName: string
  lastName: string
}

type AuthRegistrationSecondStep = {
  email: string
  registrationToken: string
}

type AuthRegistrationVerificationCode = {
  email: string
  code: string
  registrationToken: string
}

type AuthRegistrationThirdStep = {
  password: string
  registrationToken: string
}

type AuthRestorePasswordSendResetCode = {
  email: string
}

type AuthRestorePasswordVerifyResetCode = {
  email: string
  code: string
}

type AuthRestorePasswordResetPassword = {
  token: string
  newPassword: string
}

type AuthDeleteAccount = {
  token: string
  code: string
}

export default class AuthResource {
  private resourceUrl = '/auth'

  login(data: AuthLogin) {
    return request<{
      access_token: string
    }>({
      method: 'post',
      url: this.resourceUrl + '/login',
      data,
    })
  }

  loginGoogleByCode(code: string) {
    return request<{
      token: string
      isNewUser: boolean
    }>({
      method: 'post',
      url: this.resourceUrl + '/google/auth',
      data: {
        code,
      },
    })
  }

  registrationFirstStep(data: AuthRegistrationFirstStep) {
    return request<string>({
      method: 'post',
      url: this.resourceUrl + '/registration/first-step',
      data,
    })
  }

  registrationSecondStep(data: AuthRegistrationSecondStep) {
    return request<{
      message: string
    }>({
      method: 'post',
      url: this.resourceUrl + '/registration/second-step',
      data,
    })
  }

  registrationVerificationCode(data: AuthRegistrationVerificationCode) {
    return request<{
      message: string
    }>({
      method: 'post',
      url: this.resourceUrl + '/registration/verification-code',
      data,
    })
  }

  registrationThirdStep(data: AuthRegistrationThirdStep) {
    return request<{
      token: string
      isNewUser: boolean
    }>({
      method: 'post',
      url: this.resourceUrl + '/registration/third-step',
      data,
    })
  }

  restorePasswordSendResetCode(data: AuthRestorePasswordSendResetCode) {
    return request<{
      message: string
    }>({
      method: 'post',
      url: this.resourceUrl + '/restore-password/send-reset-code',
      data,
    })
  }

  restorePasswordVerifyResetCode(data: AuthRestorePasswordVerifyResetCode) {
    return request<{
      token: string
    }>({
      method: 'post',
      url: this.resourceUrl + '/restore-password/verify-reset-code',
      data,
    })
  }

  restorePasswordResetPassword(data: AuthRestorePasswordResetPassword) {
    return request<{
      message: string
    }>({
      method: 'post',
      url: this.resourceUrl + '/restore-password/reset-password',
      data,
    })
  }

  sendCodeDeleteAccount() {
    return request<{
      message: string
      token: string
    }>({
      method: 'post',
      url: this.resourceUrl + `/send-code-delete-account`,
    })
  }

  deleteAccount(data: AuthDeleteAccount) {
    return request<{
      message: string
    }>({
      method: 'post',
      url: this.resourceUrl + `/delete-account`,
      data,
    })
  }

  generateAccountChild() {
    return request<{
      login: string
      password: string
    }>({
      method: 'post',
      url: this.resourceUrl + '/generate-account-child',
    })
  }
}
