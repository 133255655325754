<script setup lang="ts">
import { ref, watch } from 'vue'
import { useField } from 'vee-validate'
import { Schema } from 'yup'

const model = defineModel<string | null>()
const props = defineProps<{
  id: string
  rules?: Schema
  placeholder?: string
  class?: string
  label?: string
  hint?: string
}>()
const emit = defineEmits<{
  blur: []
  focus: []
  change: []
}>()

const input = ref<HTMLInputElement | null>(null)

const {
  value: validateValue,
  errorMessage,
  handleReset,
  validate: fieldValidate,
} = useField(props.id, props.rules)

const onFocus = () => {
  handleReset()
  emit('focus')
}

const onBlur = () => {
  emit('blur')
}

const onChange = () => {
  emit('change')
}

const doFocus = () => {
  input.value?.focus()
}

const validate = async (): Promise<boolean> => {
  validateValue.value = model.value

  return (await fieldValidate()).valid
}

watch(model, () => {
  if (input.value) {
    input.value.style.height = 'auto'
    input.value.style.height = input.value.scrollHeight + 2 + 'px'
  }
})

defineExpose({
  doFocus,
  validate,
})
</script>

<template>
  <div :class="`field-wrapper ${props.class ?? ''}`">
    <label
      v-if="label"
      :for="id"
    >
      {{ label }}
    </label>

    <textarea
      ref="input"
      v-model="model"
      :id="id"
      :class="{ error: !!errorMessage }"
      :placeholder="props.placeholder ?? ''"
      @focus="onFocus"
      @blur="onBlur"
      @change="onChange"
    ></textarea>

    <div
      v-if="hint"
      v-show="!errorMessage"
      class="hint"
    >
      <span class="icon-mail"></span>
      {{ hint }}
    </div>

    <div
      v-show="!!errorMessage"
      class="error-msg"
    >
      <span class="icon-error"></span>
      {{ errorMessage }}
    </div>

    <slot/>
  </div>
</template>
