<script setup lang="ts">
import { ref } from 'vue'

const model = defineModel()
const props = defineProps<{
  id: string
  class?: string
}>()
const emit = defineEmits<{
  click: [Event]
}>()

const input = ref<HTMLInputElement | null>(null)
</script>

<template>
  <div :class="`field-wrapper field-checkbox ${props.class ?? ''}`">
    <input
      ref="input"
      v-model="model"
      :id="id"
      type="checkbox"
    />
    <label
      :for="id"
      @click="(event) => emit('click', event)"
    ></label>

    <slot/>
  </div>
</template>

<style lang="scss" scoped>
input {
  display: none;
}

label {
  position: relative;
  margin: 0;
  display: block;
  width: 18px;
  height: 18px;
  border: 1px solid #222222;
  border-radius: 9px;

  input:checked ~ & {
    background: #015bb0;
    border-color: #015bb0;

    &:after {
      content: '';
      position: absolute;
      left: 4px;
      top: 4px;
      display: block;
      width: 10px;
      height: 10px;
      background: url('../../assets/img/i-check.svg') no-repeat center center;
    }
  }
}
</style>
