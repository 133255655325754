<script setup lang="ts">
import { inject, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { LocalEventBus } from '@src/event-bus.ts'
import FormText from '@src/components/form/FormText.vue'
import MainSlider from '@src/components/MainSlider.vue'
import Plans2 from '@src/components/Plans2.vue'
import BnrFree from '@src/components/BnrFree.vue'

const router = useRouter()
const eventBus = inject(LocalEventBus)

let isSearchShow = true

const goToSearch = () => {
  eventBus?.emit('topSearch', 'hide')
  router.push({ name: 'search' })
}

onMounted(() => {
  window.addEventListener('scroll', () => {
    if (!isSearchShow && window.scrollY > 55) {
      eventBus?.emit('topSearch', 'show')
      isSearchShow = true
    }

    if (isSearchShow && window.scrollY < 55) {
      eventBus?.emit('topSearch', 'hide')
      isSearchShow = false
    }
  })
})
</script>

<template>
  <FormText
    id="top-search"
    type="search"
    class="only-mobile"
    placeholder="поиск дневника"
    @focus="goToSearch"
  />

  <h2 class="only-mobile">Дневник</h2>

  <main class="with-line">
    <div class="grid-1-2">
      <MainSlider />
    </div>

    <div class="grid-1-1">
      <h1 class="only-desktop">Дневник</h1>
      <h3 class="only-desktop">Послание вашему ребенку в будущее!</h3>
      <h4 class="only-mobile">Послание вашему ребенку в будущее!</h4>

      <p><strong>Зачем этот проект?</strong></p>
      <p>
        Неравнодушные и любящие отцы придумали этот проект, которые проходят
        этот «ад» отчуждения детей. И у которых, нет другой возможности написать
        своим детям весточку.
      </p>
      <p>
        Пишите, добавляйте фото и видео о вашей жизни. Рано или поздно, ваши
        дети подрастут, и если вам повезёт - вы сможете передать им доступ к
        вашему дневнику.
      </p>
      <p><strong>Удачи и сил Вам, Мужчины!</strong></p>

      <RouterLink
        class="btn primary only-desktop"
        :to="{ name: 'register' }"
      >
        Создать Дневник!
      </RouterLink>
    </div>

    <div class="grid-line">
      <BnrFree/>
    </div>

    <div class="grid-2">
      <h3 class="only-desktop">Готовы? Выберите план</h3>
      <h4 class="only-mobile">Готовы? Выберите план</h4>

      <p class="only-mobile"><strong>Попробуйте Стартовый, если понравится, всегда можно повысить план:</strong></p>

      <p class="text-mini">
        Выберите План, чтобы продолжить
        <span class="emotions-heart-hands"></span>
      </p>
    </div>

    <div class="grid-3">
      <Plans2 />

      <div class="grid-3--inner">
        <div>
          <p>
            В любом Плане: Вы сможете вести и хранить текстовые записки и фотографии
            <strong>БЕСПЛАТНО И НАВСЕГДА!</strong>
          </p>
          <p>
            По окончании подписки <strong>ПРЕМИУМ Плана</strong>:<br/>
            у Вас останется доступ к аккаунту, все записи в вашем Дневнике будут сохранены, так же Вы сможете
            скачать/удалить записи, но не сможете пользоваться удобствами <strong>ПРЕМИУМ Плана</strong>.
          </p>
        </div>

        <RouterLink
          class="btn primary only-desktop"
          :to="{ name: 'register' }"
        >
          Создать Дневник!
        </RouterLink>
      </div>
    </div>
  </main>

  <div class="overlay only-mobile"></div>

  <RouterLink
    class="btn primary only-mobile"
    :to="{ name: 'register' }"
  >
    Создать Дневник!
  </RouterLink>
</template>

<style scoped lang="scss">
main {
  margin-bottom: 100px;
  color: #001940;

  @media screen and (min-width: $desktop) {
    margin-top: 60px;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 600px 560px;
    grid-column-gap: 40px;
    grid-row-gap: 90px;
    align-items: center;

    .grid-1-1 {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }

    .grid-1-2 {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
    }

    .grid-2-1 {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }

    .grid-2-2 {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
      font-size: 15px;
    }

    .grid-2 {
      grid-row: 2 / 3;
      grid-column: 1 / 3;
    }

    .grid-3 {
      grid-row: 3 / 4;
      grid-column: 1 / 3;
      margin-top: -80px;
      display: flex;
      flex-flow: row nowrap;
      gap: 45px;

      &--inner {
        flex-grow: 1;
        margin: 24px 0;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.3;

        .btn {
          margin-left: 0;
        }
      }
    }

    &.with-line {
      .grid-line {
        grid-row: 2 / 3;
        grid-column: 1 / 3;
      }

      .grid-2-1 {
        grid-row: 3 / 4;
      }

      .grid-2-2 {
        grid-row: 3 / 4;
      }
    }
  }

  &.with-line {
    .grid-2 {
      display: none;
    }

    .grid-3 {
      display: none;
    }
  }
}

h1,
h2 {
  margin: 40px 0 20px;
  text-align: center;

  @media screen and (min-width: $desktop) {
    margin: 0 0 35px;
    text-align: center;
  }
}

h3,
h4 {
  margin: 25px 0 20px;
  color: #015bb0;

  @media screen and (min-width: $desktop) {
    margin: 0 0 25px;
  }
}

.text-mini {
  display: inline-flex;
  align-items: center;
}

.overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 140px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 1) 80%
  );
}

.btn {
  position: fixed;
  left: 50%;
  bottom: 30px;
  width: 255px;
  max-width: 100%;
  transform: translateX(-50%);

  @media screen and (min-width: $desktop) {
    position: static;
    margin: 35px auto 0;
    display: block;
    transform: none;
  }
}

.emotions-heart-hands {
  margin-left: 12px;
  --size: 40;
}
</style>
