import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import guestRoutes from '@src/router/guest.ts'
import authRoutes from '@src/router/auth.ts'
import childRoutes from '@src/router/child.ts'
import { api } from '@src/api'
import { useAuthStore } from '@src/store/auth.ts'
import MainLayout from '@src/layouts/MainLayout.vue'

declare module 'vue-router' {
  // noinspection JSUnusedGlobalSymbols
  interface RouteMeta {
    topBar?: 'calendar' | 'profile' | 'remove'
    permission?: 'guest' | 'user' | 'child'
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: MainLayout,
    children: guestRoutes,
  },
  {
    path: '/',
    component: MainLayout,
    children: authRoutes,
    meta: {
      permission: 'user',
    },
  },
  {
    path: '/',
    component: MainLayout,
    children: childRoutes,
    meta: {
      permission: 'child',
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to) => {
  const authStore = useAuthStore()

  if (!authStore.firstLoaded) {
    await authStore.loadFromStorage()
    authStore.firstLoaded = true
  }

  switch (to.meta.permission) {
    case 'guest':
      if (authStore.isAuth) {
        router.replace({ name: 'home' }).then()
      }
      break
    case 'user':
    case 'child':
      if (!authStore.isAuth) {
        router.replace({ name: 'login' }).then()
      } else {
        if (authStore.user?.role === 'user' && to.meta.permission === 'child') {
          router.replace({ name: 'home' }).then()
        } else if (authStore.user?.role === 'child' && to.meta.permission === 'user') {
          router.replace({ name: 'diary' }).then()
        }
      }
      break
  }
})

router.afterEach((to) => {
  api.metatag
    .get('/' + to.path.split('/')[1])
    .then((data) => {
      document.title = data.title
      document.querySelector('meta[name="description"]')?.setAttribute('content', data.description)
    })

  if (to.name === 'home' || to.name === 'calendar' || to.name === 'diary') {
    document.body.classList.add('to-bottom')
  } else {
    document.body.classList.remove('to-bottom')
  }
})

export default router
