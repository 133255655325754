<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { api } from '@src/api'
import Validator, { Yup } from '@src/validator.ts'
import FormText from '@src/components/form/FormText.vue'
import { useAuthStore } from '@src/store/auth.ts'

const router = useRouter()

const authStore = useAuthStore()
const isLoading = ref(false)

const validator = new Validator()
const login = ref('')
const password = ref('')

const handleLogin = () => {
  if (!isLoading.value) {
    isLoading.value = true

    validator.validateAll().then((result) => {
      if (result) {
        api.auth
          .login({
            loginOrEmail: login.value,
            password: password.value,
          })
          .then(async ({ access_token }) => {
            await authStore.setAccessToken(access_token)

            if (authStore.user?.role === 'child') {
              await router.push({ name: 'diary' })
            } else {
              await router.push({ name: 'home' })
            }
          })
          .finally(() => {
            isLoading.value = false
          })
      } else {
        isLoading.value = false
      }
    })
  }
}
</script>

<template>
  <main>
    <div class="form-block">
      <form ref="form">
        <h2>Вход</h2>

        <img
          class="only-mobile"
          src="../assets/img/img-login.gif"
          alt="Login"
        />

        <FormText
          v-model="login"
          :ref="(el: typeof FormText) => validator.addItem('name', el)"
          id="name"
          type="email"
          :rules="Yup.string().required()"
          label="Логин:"
        />
        <FormText
          v-model="password"
          :ref="(el: typeof FormText) => validator.addItem('password', el)"
          id="password"
          type="password"
          :rules="Yup.string().required()"
          label="Пароль:"
        />
      </form>

      <div class="actions">
        <div
          class="btn primary"
          :class="{ loading: isLoading }"
          @click="handleLogin"
        >
          Войти
        </div>

        <RouterLink
          :to="{ name: 'restore-password' }"
          class="btn"
        >
          Забыли пароль?
        </RouterLink>

        <div class="alt-block">
          <p>
            Нет аккаунта?
            <RouterLink :to="{ name: 'register' }">Регистрация</RouterLink>
          </p>
        </div>
      </div>
    </div>
    <div class="img-block only-desktop">
      <img
        src="../assets/img/img-login.gif"
        alt="Login"
      />
    </div>
  </main>
</template>

<style scoped lang="scss">
main {
  padding-top: 25px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: calc(100dvh - 105px);
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    padding-top: 130px;
    align-items: center;
    min-height: 0;

    > div {
      flex: 0 0 560px;
    }
  }
}

.form-block {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
  text-align: center;

  @media screen and (min-width: $desktop) {
    align-items: center;
  }

  h2 {
    margin-bottom: 0;
    text-align: center;

    @media screen and (min-width: $desktop) {
      margin-bottom: 40px;
    }
  }

  form {
    img {
      margin: 0 auto;
      display: block;
      width: 210px;
      height: 210px;
      max-width: 100%;
    }

    .field-wrapper {
      display: inline-block;
      width: 100%;

      @media screen and (min-width: $desktop) {
        width: 335px;
      }
    }
  }

  .actions {
    margin-top: 30px;

    @media screen and (min-width: $desktop) {
      margin-top: 60px;
    }
  }

  .btn {
    margin: 0 auto;
    display: block;
    width: 255px;
    max-width: 100%;

    + .btn {
      margin-top: 25px;

      @media screen and (min-width: $desktop) {
        margin-top: 30px;
      }
    }
  }
}

.img-block {
  img {
    margin: 0 auto;
    display: block;
    width: 500px;
    height: 500px;
    max-width: 100%;
  }
}

.alt-block {
  margin: 15px auto 0;
  width: 255px;
  max-width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 400;

  @media screen and (min-width: $desktop) {
    margin-top: 20px;
    width: 335px;
    font-size: 15px;
    font-weight: 500;
  }

  > p {
    color: rgba(34, 34, 34, 0.8);
  }
}
</style>
