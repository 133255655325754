<script setup lang="ts">
import { useRouter } from 'vue-router'
import { api } from '@src/api'
import { useAuthStore } from '@src/store/auth.ts'
import PlansAuth from '@src/components/PlansAuth.vue'
import Plans2 from '@src/components/Plans2.vue'

const router = useRouter()
const authStore = useAuthStore()

const upgradePlan = async () => {
  await api.users.premium(true)
  await authStore.updateProfile()
  void router.push({ name: 'greetings' })
}
</script>

<template>
  <main>
    <PlansAuth
      v-if="authStore.isAuth"
      :is-premium="authStore.user?.isPremium ?? false"
    ></PlansAuth>
    <div v-else>
      <h2>Планы и тарифы</h2>

      <Plans2 add-event></Plans2>
    </div>

    <div v-if="authStore.isAuth">
      <template v-if="authStore.user?.isPremium ?? false">
        <RouterLink
          class="btn primary"
          :to="{ name: 'home' }"
        >
          Ок
        </RouterLink>
      </template>
      <template v-else>
        <div
          class="btn primary"
          @click="upgradePlan"
        >
          Повысить план
        </div>

        <RouterLink
          class="btn"
          :to="{ name: 'home' }"
        >
          Напомнить позже
        </RouterLink>
      </template>
    </div>
    <div v-else>
      <RouterLink
        class="btn primary"
        :to="{ name: 'register' }"
      >
        Создать Дневник!
      </RouterLink>
    </div>
  </main>
</template>

<style scoped lang="scss">
main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: calc(100dvh - 105px);
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    margin: 0 auto;
    padding-top: 130px;
    width: 701px;
    min-height: 0;
  }

  h2 {
    margin-bottom: 0;
    text-align: center;

    @media screen and (min-width: $desktop) {
      margin-bottom: 40px;
    }
  }
}

.btn {
  margin: 30px auto 0;
  display: block;
  width: 255px;
  max-width: 100%;

  + .btn {
    margin-top: 25px;
  }
}
</style>