<script setup lang="ts">

</script>

<template>
  <div class="backdrop">
    <img
      src="../assets/img/loading.svg"
      alt="Loading"
    />
  </div>
</template>

<style scoped lang="scss">
.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: rgba(235, 251, 255, 0.95);
  z-index: 199;

  img {
    width: 70px;

    @media screen and (min-width: $desktop) {
      width: 100px;
    }
  }
}
</style>