import { request } from '@src/axios.ts'

export type TariffType = {
  id: number
  name: string
  description: string
  price: string
  originalPrice: string
  duration: string
  features: string
  emoji: string
  durationMonths: number
  isEnabled: boolean
}

export default class TariffsResource {
  private resourceUrl = 'tariffs'

  list() {
    return request<TariffType[]>({
      method: 'get',
      url: this.resourceUrl,
    })
  }
}
