import { defineStore } from 'pinia'

export const useSearchStore = defineStore('search', {
  state: (): {
    query: string
    resultsTotal: number | null
    runSearch: boolean
  } => {
    return {
      query: '',
      resultsTotal: null,
      runSearch: false,
    }
  }
})
