<script setup lang="ts">
import { ref } from 'vue'
import { useAuthStore } from '@src/store/auth.ts'

const authStore = useAuthStore()
const isPremium = ref(false)

authStore.$subscribe(
  (_, state) => {
    isPremium.value = state.user?.isPremium ?? false
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <main>
    <div class="spacer-1"></div>
    <div class="greetings">
      <div class="greetings--content">
        <h2>Поздравляем!</h2>

        <div class="img-block only-mobile">
          <template v-if="isPremium">
            <div class="emotions-premium"></div>
          </template>
          <template v-else>
            <img
              class="house"
              src="../../assets/img/img-greetings.gif"
              alt="Greetings"
            />

            <img
              class="inner-logo"
              src="../../assets/img/img-greetings-logo.png"
              alt="Logo"
            />
          </template>
        </div>

        <div class="text">
          <span class="emotions-confetti-ball"></span>
          Вы сделали правильный шаг
          <span class="emotions-confetti-ball"></span>
          <br />
          <template v-if="isPremium">
            Вам повезло и Вы счастливчик Пользуйтесь на здоровье всеми удобствами Премиум плана.
          </template>
          <template v-else>
            Пишите сколько угодно и когда угодно.<br />Желаем Вам удачи!
          </template>
        </div>

        <RouterLink
          class="btn primary only-desktop"
          :to="{ name: 'home' }"
        >
          Перейти в дневник
        </RouterLink>
      </div>

      <div class="img-block only-desktop">
        <template v-if="isPremium">
          <div class="emotions-premium"></div>
        </template>
        <template v-else>
          <img
            class="house"
            src="../../assets/img/img-greetings.gif"
            alt="Greetings"
          />

          <img
            class="inner-logo"
            src="../../assets/img/img-greetings-logo.png"
            alt="Logo"
          />
        </template>
      </div>
    </div>
    <div class="spacer-2"></div>
    <div class="actions only-mobile">
      <RouterLink
        :to="{ name: 'home' }"
        class="btn primary"
      >
        Перейти в дневник
      </RouterLink>
    </div>
  </main>
</template>

<style scoped lang="scss">
main {
  display: flex;
  flex-flow: column nowrap;
  min-height: calc(100dvh - 105px);

  @media screen and (min-width: $desktop) {
    display: block;
  }
}

.spacer-1 {
  flex-grow: 1;
}

.spacer-2 {
  flex-grow: 2;
}

.greetings {
  padding: 20px 20px 30px;
  background: #ffffff;
  border-radius: 30px;
  box-shadow: 0 20px 40px 0 #cad9ef66;
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    margin: 90px auto 0;
    padding: 45px 70px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 960px;
    box-sizing: border-box;
  }

  .img-block {
    position: relative;

    > img.house {
      @media screen and (max-width: $desktopM) {
        width: 200px;
        margin-top: 15px;
        margin-left: -72px;
        margin-bottom: 17px;
      }

      @media screen and (min-width: $desktop) {
        width: 350px;
        margin-left: 25px;
        margin-top: 40px;
        margin-bottom: 35px;
      }
    }

    > img.inner-logo {
      position: absolute;

      @media screen and (max-width: $desktopM) {
        top: 106px;
        left: 27px;
        width: 140px;
      }

      @media screen and (min-width: $desktop) {
        top: 200px;
        left: 200px;
      }
    }

    > div {
      --size: 160;

      @media screen and (min-width: $desktop) {
        --size: 240;
        margin-left: 120px;
      }
    }
  }

  &--content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    @media screen and (min-width: $desktop) {
      text-align: center;
    }

    h2 {
      margin-bottom: 5px;

      @media screen and (min-width: $desktop) {
        margin-bottom: 50px;
      }
    }

    .btn {
      margin-top: 50px;
    }
  }
}

.emotions-confetti-ball {
  margin-right: 5px;
  --size: 20;

  ~ .emotions-confetti-ball {
    margin-left: 5px;
  }
}
</style>
