import { request } from '@src/axios.ts'

type HelpSend = {
  email: string
  subject: string
  message: string
}

export default class HelpResource {
  private resourceUrl = '/help'

  send(data: HelpSend) {
    return request<{
      id: number
      email: string
      subject: string
      message: string
      createdAt: string
    }>({
      method: 'post',
      url: this.resourceUrl,
      data,
      silent: true,
    })
  }
}
