import { RouteRecordRaw } from 'vue-router'
import Diary from '@src/pages/child/Diary.vue'
import Profile from '@src/pages/child/Profile.vue'

const childRoutes: RouteRecordRaw[] = [
  {
    path: 'diary',
    component: Diary,
    name: 'diary',
  },
  {
    path: 'owner',
    component: Profile,
    name: 'owner',
    meta: {
      topBar: 'profile',
    },
  },
]

export default childRoutes
