import { api, request } from '@src/axios.ts'
import { AxiosProgressEvent, GenericAbortSignal } from 'axios'

export default class FilesResources {
  private resourceUrl = '/files'

  carouselFiles() {
    return request<{
      file: string
      isPremium: boolean
    }[]>({
      method: 'get',
      url: this.resourceUrl + '/carousel-files',
    })
  }

  // noinspection JSUnusedGlobalSymbols
  get(uuid: string, type: 'full' | 'thumbnail') {
    return request<Blob>({
      method: 'get',
      url: `${this.resourceUrl}/${uuid}`,
      params: {
        type,
      },
      responseType: 'blob',
    })
  }

  uploadImage(
    destination: 'AVATAR' | 'DIARY',
    file: Blob,
    signal?: GenericAbortSignal,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  ): Promise<{
    message: string
    data: string
  }> {
    let formData = new FormData()
    formData.append('file', file)

    return api.post(`${this.resourceUrl}/image/${destination}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      signal,
      onUploadProgress,
    })
  }

  uploadAudio(file: Blob): Promise<{
    message: string
    data: string
  }> {
    let formData = new FormData()
    formData.append('file', file, 'audio.ogg')

    return api.post(`${this.resourceUrl}/audio`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  uploadVideo(
    file: Blob,
    signal?: GenericAbortSignal,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  ): Promise<{
    message: string
    data: string
  }> {
    let formData = new FormData()
    formData.append('file', file)

    return api.post(`${this.resourceUrl}/video`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      signal,
      onUploadProgress,
    })
  }
}
