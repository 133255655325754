<script setup lang="ts">
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@src/store/auth.ts'

const route = useRoute()
const router = useRouter()

onMounted(async () => {
  if (typeof route.query.token === 'string') {
    await useAuthStore().setAccessToken(route.query.token ?? '')

    if (route.query.isNewUser === 'true') {
      await router.push({ name: 'greetings' })
    } else {
      await router.push({ name: 'home' })
    }

    return
  }

  await router.push({ name: 'register' })
})
</script>

<template>
  <img
    src="../../assets/img/loading.svg"
    alt="Loading"
  />
</template>

<style scoped lang="scss">
img {
  margin: 25px auto;
  display: block;
  width: 50px;

  @media screen and (min-width: $desktop) {
    margin: 130px auto;
    width: 80px;
  }
}
</style>
