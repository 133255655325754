import { defineStore } from 'pinia'
import { toast } from 'vue3-toastify'
import { api } from '@src/api'

const authToken = 'authToken'

type UserData = {
  id: number
  email: string
  login: string
  role: 'admin' | 'user' | 'child'
  fullName: string
  avatar: string | null
  birthDate: string | null
  isPremium: boolean
  isShowDiary: boolean
  isShowPhotoMain: boolean
  isMotivationEnabled: boolean
  createdAt: string
}

type ParentData = {
  fullName: string
  avatar: string | null
  birthDate: string | null
  createdAt: string
}

export const useAuthStore = defineStore('auth', {
  state: (): {
    accessToken: string | null
    user: UserData | null
    parent: ParentData | null
    firstLoaded: boolean
  } => {
    return {
      accessToken: null,
      user: null,
      parent: null,
      firstLoaded: false,
    }
  },

  getters: {
    isAuth: (state) => state.accessToken !== null,
  },

  actions: {
    async loadFromStorage() {
      this.accessToken = localStorage.getItem(authToken)

      if (this.accessToken !== null) {
        await this.updateProfile()
      }
    },
    async setAccessToken(token: string) {
      this.accessToken = token
      localStorage.setItem(authToken, token)
      await this.updateProfile()
    },
    clearAccessToken() {
      this.accessToken = null
      this.user = null
      localStorage.removeItem(authToken)
    },
    setUserData(user: UserData) {
      this.user = user
    },
    async updateProfile() {
      const data = await api.users.profile()

      if (['user', 'child'].includes(data.role)) {
        this.setUserData({
          ...data,
          fullName: data.fullName ?? '',
        })

        if (data.role === 'child') {
          this.parent = await api.users.parent()
        }
      } else {
        toast.warning('Нет доступа к личному кабинету')
        this.clearAccessToken()
      }
    },
    setPremium(value: boolean) {
      if (this.user) {
        this.user.isPremium = value
      }
    },
    setShowDiary(value: boolean) {
      if (this.user) {
        this.user.isShowDiary = value
      }
    },
    setShowPhotoMain(value: boolean) {
      if (this.user) {
        this.user.isShowPhotoMain = value
      }
    },
    setMotivationEnabled(value: boolean) {
      if (this.user) {
        this.user.isMotivationEnabled = value
      }
    },
  },
})
