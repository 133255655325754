import * as Yup from 'yup'
import FormText from '@src/components/form/FormText.vue'
import FormArea from '@src/components/form/FormArea.vue'

Yup.setLocale({
  mixed: {
    required: 'обязательное поле',
  },
  string: {
    email: 'требуется адрес электронной почты',
  },
})

export { Yup }

export default class Validator {
  public fields: Record<string, typeof FormText | typeof FormArea> = {}

  addItem(name: string, element: typeof FormText | typeof FormArea) {
    this.fields[name] = element

    return name
  }

  setError(name: string, message: string) {
    this.fields[name].setErrors(message)
  }

  async validate(...fields: string[]): Promise<boolean> {
    return (
      await Promise.all(
        fields.map((name) =>
          this.fields[name] ? this.fields[name].validate() : false,
        ),
      )
    ).reduce((valid, current) => valid && current, true)
  }

  async validateAll(): Promise<boolean> {
    return (
      await Promise.all(
        Object.keys(this.fields).map((name) =>
          this.fields[name] ? this.fields[name].validate() : false,
        ),
      )
    ).reduce((valid, current) => valid && current, true)
  }
}
