<script setup lang="ts">
defineProps<{
  mobile?: boolean
}>()
</script>

<template>
  <div
    class="bnr-wrapper"
    :class="{ mobile }"
  >
    <div class="bnr--top-text only-mobile">
      <div class="emotions-confetti-ball"></div>
      В честь запуска проекта:
    </div>

    <div class="bnr--main">
      <div class="bnr--top-text only-desktop">
        <div class="emotions-confetti-ball"></div>
        В честь запуска проекта:
      </div>

      <div class="h3">Спецпредложение!</div>

      <img
        class="bnr--count only-mobile"
        src="../assets/img/bnr-mob-count.png"
        alt="2000 премиум дневников бесплатно и пожизненно"
      />

      <img
        class="bnr--count only-desktop"
        src="../assets/img/bnr-desk-count.png"
        alt="2000 премиум дневников бесплатно и пожизненно"
      />

      <img
        class="bnr--image"
        src="../assets/img/img-free.gif"
        alt="Free"
      />

      <img
        class="logo"
        src="../assets/img/logo-icon.png"
        alt="Logo"
      />

      <div class="bnr--features">
        <div class="bnr--features--item">
          <img src="../assets/img/img-bnr-video.png" alt="видеофайлы"/>
          <p>видео&shy;файлы</p>
        </div>

        <div class="bnr--features--item">
          <img src="../assets/img/img-bnr-mic.png" alt="голосовые записки"/>
          <p>голосовые записки</p>
        </div>

        <div class="bnr--features--item">
          <img src="../assets/img/img-bnr-crown.png" alt="премиум аватарка"/>
          <p>премиум аватарка</p>
        </div>

        <div class="bnr--features--item">
          <img src="../assets/img/img-bnr-gift.png" alt="новогодняя лотерея"/>
          <p>новогодняя лотерея</p>
        </div>
      </div>
    </div>

    <div class="bnr--bottom-text">
      <span>*</span>акция действует <span>до создания 2000 профилей</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bnr-wrapper {
  margin: 40px 0;

  &.mobile {
    width: 100%;

    .only-desktop {
      display: none !important;
    }
  }

  @media screen and (min-width: $desktop) {
    margin: 0;
  }
}

.bnr {
  &--top-text {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 14px;

    @media screen and (min-width: $desktop) {
      justify-content: left;
      font-size: 17px;
    }

    .mobile & {
      display: flex !important;
      justify-content: center;
    }

    [class*='emotions-'] {
      --size: 24;
    }
  }

  &--bottom-text {
    font-size: 14px;
    text-align: center;

    span {
      color: #d10c0c;
    }
  }

  &--main {
    position: relative;
    margin: 10px 0;
    padding: 15px 2px;
    height: 490px;
    background: #ffffff;
    border-radius: 30px;
    box-shadow: 0 20px 40px 0 #cad9ef66;

    @media screen and (min-width: $desktop) {
      margin: 0 0 15px;
      padding: 34px 0 30px 623px;
      height: auto;
    }

    .mobile & {
      margin-top: 15px;
      padding: 30px;
      height: 380px;
    }

    .h3 {
      font-size: 32px;
      color: #015bb0;
      text-align: center;

      @media screen and (min-width: $desktop) {
        margin-top: 23px;
        font-size: 32px;
        text-align: left;
      }

      .mobile & {
        margin-top: 0;
        text-align: center;
      }
    }

    .logo {
      position: absolute;
      bottom: 128px;
      left: 21px;
      width: 58px;

      @media screen and (min-width: $desktop) {
        top: 89px;
        left: 65px;
        width: 75px;
      }

      .mobile & {
        bottom: 20px;
        left: 20px;
      }
    }
  }

  &--text-block {
    margin-top: 20px;
    padding: 13px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 14px;
    background: linear-gradient(341.5deg, #10bdff -27.59%, #005ac4 234.81%);
    border-radius: 16px;
    box-shadow:
      0 20px 40px 0 #cad9efcc,
      3px 1px 15px 0 #ffffff80 inset,
      0px -3px 10px 0px #00000033 inset;

    @media screen and (min-width: $desktop) {
      margin-top: 24px;
      padding: 16px 14px;
      width: 330px;
    }

    .mobile & {
      width: auto;
    }

    &--left {
      flex-shrink: 0;
      font-size: 48px;
      font-weight: 600;
      color: #ffffff;

      @media screen and (min-width: $desktop) {
        font-size: 53px;
      }
    }

    &--right {
      flex: 0 0 150px;
      width: 150px;
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
      line-height: 1.2;

      @media screen and (min-width: $desktop) {
        flex: 1 0 auto;
        width: auto;
        font-size: 22px;
        line-height: 1.4;
      }

      .mobile & {
        flex: 0 0 180px;
        width: 180px;
      }

      .highlight {
        font-size: 11px;
        color: #ffe91b;
        line-height: 1;

        @media screen and (min-width: $desktop) {
          font-size: 13px;
        }
      }
    }
  }

  &--count {
    margin: 0 auto;
    display: block;
    max-width: 100%;

    @media screen and (min-width: $desktop) {
      margin-left: -45px;
    }
  }

  &--image {
    position: absolute;
    right: -20px;
    bottom: 50px;
    width: 360px;

    @media screen and (min-width: $desktop) {
      right: 619px;
      bottom: 32px;
      width: 400px;
    }

    .mobile & {
      right: 0;
      bottom: -75px;
    }
  }

  &--features {
    position: absolute;
    bottom: 16px;
    left: 15px;
    right: 15px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    @media screen and (min-width: $desktop) {
      position: static;
      margin-left: -549px;
      margin-right: 74px;
    }

    &--item {
      flex: 0 0 66px;
      display: flex;
      flex-flow: column;
      gap: 11px;
      align-items: center;
      width: 66px;
      font-size: 10px;
      font-weight: 700;
      line-height: 1.2;
      text-align: center;

      @media screen and (min-width: $desktop) {
        flex-basis: auto;
        flex-flow: row nowrap;
        gap: 15px;
        width: auto;
        font-size: 18px;
        line-height: 1.2;
      }
    }
  }
}
</style>
