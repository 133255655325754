import AuthResource from '@src/api/auth.ts'
import UsersResource from '@src/api/users.ts'
import DiariesResource from '@src/api/diaries.ts'
import FilesResources from '@src/api/files.ts'
import TariffsResource from '@src/api/tariffs.ts'
import HelpResource from '@src/api/help.ts'
import MetatagResource from '@src/api/metatag.ts'

export const api = {
  auth: new AuthResource(),
  users: new UsersResource(),
  diaries: new DiariesResource(),
  files: new FilesResources(),
  tariffs: new TariffsResource(),
  help: new HelpResource(),
  metatag: new MetatagResource(),
}
