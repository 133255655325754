<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@src/store/auth.ts'
import { api } from '@src/api'
import Validator, { Yup } from '@src/validator.ts'
import FormText from '@src/components/form/FormText.vue'

const warningContent = [
  'Не сможете создавать новые записки',
  'Все записки и медиа файлы в дневнике будут удалены безвозвратно',
  'Оплаченный план будет утерян, включая бессрочный',
]

const router = useRouter()
const authStore = useAuthStore()

const validator = new Validator()
const token = ref('')
const emailCode = ref('')
const isLoading = ref(false)

const sendEmailCode = () => {
  api.auth.sendCodeDeleteAccount().then((data) => {
    token.value = data.token
  })
}

const handleDelete = () => {
  if (!isLoading.value) {
    isLoading.value = true

    validator.validateAll().then((result) => {
      if (result && authStore.user) {
        api.auth
          .deleteAccount({
            token: token.value,
            code: emailCode.value.toUpperCase(),
          })
          .then(() => {
            authStore.clearAccessToken()
            router.push({ name: 'index' })
          })
          .finally(() => {
            isLoading.value = false
          })
      } else {
        isLoading.value = false
      }
    })
  }
}

onMounted(() => {
  sendEmailCode()
})
</script>

<template>
  <main>
    <div class="form-block">
      <form ref="form">
        <h2>Удалить аккаунт?</h2>

        <div class="warning-block only-mobile">
          <div class="warning-block--header">
            <div class="emotions-loudly-crying-face"></div>
            <p>Удалив аккаунт, Вы</p>
          </div>

          <div class="warning-block--content">
            <ul>
              <li v-for="item in warningContent">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>

        <FormText
          v-model="emailCode"
          :ref="(el: typeof FormText) => validator.addItem('emailCode', el)"
          id="emailCode"
          :rules="Yup.string().required()"
          type="code"
          label="Код верификации Email:"
        />
      </form>

      <div class="actions">
        <div class="repeat-code">
          <p>
            Не пришел код?
            <br class="only-desktop" />
            Запросить повторно
          </p>

          <div
            :class="{ disabled: isLoading }"
            @click="sendEmailCode"
          >
            <span class="icon-refresh"></span>
          </div>
        </div>

        <div
          class="btn primary"
          :class="{ loading: isLoading }"
          @click="handleDelete"
        >
          Удалить
        </div>

        <RouterLink
          :to="{ name: 'home' }"
          class="btn"
        >
          Назад
        </RouterLink>
      </div>
    </div>

    <div class="warning-block only-desktop">
      <div class="warning-block--header">
        <div class="emotions-loudly-crying-face"></div>
        <p>Удалив аккаунт, Вы</p>
      </div>

      <div class="warning-block--content">
        <ul>
          <li v-for="item in warningContent">
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>

<style scoped lang="scss">
main {
  padding-top: 25px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: calc(100dvh - 105px);
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    padding-top: 130px;
    align-items: center;
    min-height: 0;

    > div {
      flex: 0 0 560px;
    }
  }
}

.form-block {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
  text-align: center;

  @media screen and (min-width: $desktop) {
    align-items: center;
  }

  h2 {
    margin-bottom: 30px;
    text-align: center;

    @media screen and (min-width: $desktop) {
      margin-bottom: 40px;
    }
  }

  form {
    .field-wrapper {
      display: inline-block;
      width: 100%;

      @media screen and (min-width: $desktop) {
        width: 335px;
      }
    }
  }

  .actions {
    margin-top: 30px;

    @media screen and (min-width: $desktop) {
      margin-top: 90px;
    }
  }

  .btn {
    margin: 0 auto;
    display: block;
    width: 255px;
    max-width: 100%;

    + .btn {
      margin-top: 25px;

      @media screen and (min-width: $desktop) {
        margin-top: 30px;
      }
    }
  }
}

.repeat-code {
  margin: 0 10px 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  color: rgba(34, 34, 34, 0.8);

  @media screen and (min-width: $desktop) {
    margin-bottom: 30px;
    width: 335px;
    font-size: 15px;
    font-weight: 500;
  }

  div {
    padding: 18px 0;
    width: 60px;
    text-align: center;
    font-size: 24px;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0 20px 40px 0 #cad9ef66;
    box-sizing: border-box;
    cursor: pointer;

    span:before {
      color: #015bb0;
    }
  }
}

.warning-block {
  margin-bottom: 30px;

  &--header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;

    @media screen and (min-width: $desktop) {
      margin: 0 auto;
      gap: 24px;
      width: 500px;
      font-size: 24px;
      box-sizing: border-box;
    }

    .emotions-loudly-crying-face {
      --size: 28;

      @media screen and (min-width: $desktop) {
        --size: 48;
      }
    }

    p {
      opacity: 0.8;
    }
  }

  &--content {
    margin-top: 12px;
    padding: 10px;
    text-align: left;
    color: #ff5876;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
    background: #ffffff;
    border: 1px solid #e7f4f9;
    border-radius: 15px;
    box-shadow: 0 20px 40px 0 #cad9ef66;

    @media screen and (min-width: $desktop) {
      margin: 24px auto 0;
      padding: 20px;
      width: 500px;
      font-size: 17px;
      line-height: 1.2;
      font-weight: 500;
      box-sizing: border-box;
    }

    ul {
      margin: 0;
      padding-left: 1.3em;
    }
  }
}
</style>
