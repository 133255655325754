<script setup lang="ts">
import { onUnmounted, ref } from 'vue'

defineProps<{
  src: string
}>()

const videoElement = ref<HTMLVideoElement>()

const videoPause = () => {
  videoElement.value?.pause()
}

onUnmounted(() => {
  videoPause()
})

defineExpose({
  videoPause
})
</script>

<template>
  <div class="video-player">
    <video
      ref="videoElement"
      class="video"
      playsinline
      controls
      preload="metadata"
    >
      <source :src="src + '#t=0.001'" />
      <p>Your browser doesn't support HTML5 video.</p>
    </video>
  </div>
</template>

<style scoped lang="scss">
</style>
