import { defineStore } from 'pinia'
import loadSecImage from '@src/loadSecImage.ts'

export const useAvatarStore = defineStore('avatar', {
  state: (): {
    avatarData: string | null
  } => {
    return {
      avatarData: null,
    }
  },

  actions: {
    setAvatar(uuid: string) {
      this.avatarData = loadSecImage(uuid, 'thumbnail')
    },
    clearAvatar() {
      this.avatarData = null
    },
  },
})
